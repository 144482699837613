import { useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'

export default function PresentedTalentSelect ({ value, disabled, addTalent, label, talents }) {
  const [talentToAdd, setTalentToAdd] = useState(null)

  const onClick = () => {
    if (!talentToAdd) return
    setTalentToAdd(null)
    addTalent(talentToAdd)
  }

  return (
    <Autocomplete
      disabled={disabled}
      value={talentToAdd}
      onChange={(_, value) => setTalentToAdd(value)}
      options={talents || []}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(talent) => talent.name}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )
      }}
      renderInput={(params) => (
        <Stack direction='row'>
          <TextField
            {...params}
            size='small'
            label={label}
          />
          <IconButton onClick={onClick}><AddCircleIcon /></IconButton>
        </Stack>
      )}
    />
  )
}
