/**
     * a class to retry lazy load calls, so that chunk load errors could be
     * minimized
*/

export class LazyLoadUtils {
  constructor () {
    this.hasRetried = false
  }

  static get hasRetried () {
    return window.sessionStorage.getItem('lazyRetry') || false
  }

  static set hasRetried (retried) {
    let sessionString = false
    if (retried) sessionString = true
    window.sessionStorage.setItem('lazyRetry', sessionString)
  }

  /**
     *  method for retrying lazy loads
     * @param {promise} loadComponent the component that is being loaded
     */
  retryLazyLoad (loadComponent) {
    // returns a promise to the react lazy load module
    return new Promise((resolve, reject) => {
      const hasRetried = this.hasRetried
      loadComponent().then((component) => {
        // if component has loaded, set retried to false
        this.hasRetried = false
        resolve(component)
      }).catch((error) => {
        // if we have not already retried call, refresh page to load new chunks
        if (!hasRetried) {
          this.hasRetried = true
          return window.location.reload()
        }
        reject(error)
      })
    })
  }
}

export default new LazyLoadUtils()
