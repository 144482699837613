import FirestoreTopCollection from '../google/firestore/FirestoreTopCollection'

class UsersRepository extends FirestoreTopCollection {
  constructor () {
    super('users')
  }

  async update (user, analytics, marketing) {
    await this._merge(user, { analytics, marketing }, user.id)
  }
}

const usersRep = new UsersRepository()

export default usersRep
