import { Suspense } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Spinner from './components/Spinner'
import { LocaleProvider } from './i18n/i18n'
import routes from './routes'

export default function App (props) {
  return (
    <Suspense>
      <LocaleProvider>
        <RouterProvider router={createBrowserRouter(routes)} fallbackElement={<Spinner sx={{ position: 'absolute', top: '45%', left: '50%' }} />} />
      </LocaleProvider>
    </Suspense>
  )
}
