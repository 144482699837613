import adoreJobRepository from '../../jobs/AdoreJobRepository'
export default async function loader ({ params }) {
  if (params.id === 'my') {
    return []
  }
  const document = await adoreJobRepository.getJobDocument(params.id)
  const subcollections = await adoreJobRepository.getJobInterests(params.id)

  if (!document) {
    throw new Response('', { // eslint-disable-line
      status: 404,
      statusText: 'Not Found'
    })
  }
  return { document, subcollections }
}
