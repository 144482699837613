import { redirect } from 'react-router-dom'
import { getUser } from '../google/auth/firebaseAuth'
import { pathLogin, pathPastShifts } from '../routes'
import { sites } from '../utils/sites'
export default async function loader () {
  const user = await getUser()
  if (!user && !window.localStorage.getItem('access')) {
    return redirect(pathLogin, { replace: true, state: { redirected: false } })
  }
  if (window.location.href.includes(sites.staffPoint)) {
    return redirect(pathPastShifts, { replace: true, state: { redirected: false } })
  }

  return null
}
