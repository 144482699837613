import Row from '../Row.js'

export default class AdoreJobRow extends Row {
  #cities
  #counties
  #searchTitle

  constructor (id, data) {
    super(id, data)
    const cities = this.data(4)
    const counties = this.data(3)
    this.#cities = cities ? cities.split(',') : []
    this.#counties = counties ? counties.split(',') : []
    this.#searchTitle = this.title.toLowerCase()
  }

  get recruiter () { return this.data(0) || '' }

  get title () { return this.data(2) }

  get cities () { return this.#cities }

  get counties () { return this.#counties }

  get interested () { return this.data(1) }

  hasTitle (title) { return this.#searchTitle.includes(title) }

  hasCity (city) { return this.#cities.includes(city) }

  hasCounty (county) { return this.#counties.includes(county) }

  toString () { return this.title }
}
