import FirestoreTopCollection from '../google/firestore/FirestoreTopCollection'

class ExpertiseRepository extends FirestoreTopCollection {
  constructor () {
    super('tags')
  }
}

const expertiseRep = new ExpertiseRepository()
export default expertiseRep
