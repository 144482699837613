import { deleteObject, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { storage } from '../google/storage/firebaseStorage'
import { getAuth } from 'firebase/auth'
import isEmpty from 'lodash/isEmpty'

let isWaitingSave = false
let _id
let _file
let _fileRef
let _metadata
let _fileName

export const getIsWaitingAvatarSave = () => {
  return isWaitingSave
}

export const setIsWaitingAvatarSave = (bool) => {
  isWaitingSave = bool
}

export const getFileNameAvatar = () => {
  return _fileName
}

export const setFileName = (name) => {
  _fileName = name
}

export const saveAvatar = async () => {
  try {
    const uploadTask = await uploadBytes(_fileRef, _file, _metadata)
    _id = ''
    _file = ''
    _fileRef = ''
    _metadata = ''
    _fileName = ''
    return uploadTask
  } catch (error) {
    console.debug(error)
    return error
  }
}

const _getID = async (fileName) => {
  try {
    const auth = await getAuth()
    _id = auth.currentUser.uid
    _fileRef = ref(storage, _id + '/avatar/' + fileName)
  } catch (error) {
    console.debug(error)
  }
}

export const saveAvatarRef = async (id, fileData, fileName, file) => {
  if (!id) {
    _getID(fileName)
  } else {
    _id = id
    _fileRef = ref(storage, _id + '/avatar/' + fileName)
  }
  _metadata = {
    contentType: fileData.type,
    contentDisposition: 'inline; filename="' + fileName + '"'
  }
  _fileName = fileName
  _file = file

  return _fileRef
}

export const deleteAvatar = async (id, fileName) => {
  const fileRef = ref(storage, id + '/avatar/' + fileName)
  return await deleteObject(fileRef)
}

export const loadAvatar = async (id, file) => {
  if (isEmpty(file)) return false

  const fileRef = ref(storage, `${id}/avatar/${file}`)
  try {
    const result = await getDownloadURL(fileRef)
    return result
  } catch (error) {
    switch (error.code) {
      case 'storage/object-not-found':
        console.debug('File doesn\'t exist')
        break
      case 'storage/unauthorized':
        console.debug('User doesn\'t have permission to access the object')
        break
      case 'storage/unknown':
        console.debug('Unknown error occurred, inspect the server response')
        break
      default:
        break
    }
    return false
  }
}
