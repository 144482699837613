import mobileApi from '../../mystaff/mobileApi'

export async function WorkShiftsLoader () {
  try {
    const response = await mobileApi.getPlacesOfWorkForShifts()
    return response
  } catch (e) {
    return e
  }
}

export async function WorkShiftsAction ({ request }) {
  const formData = await request.formData()
  const requestString = new window.URLSearchParams(formData).toString()
  const response = await mobileApi.getDoneWorkShifts(requestString)
  if (response.status === 200) {
    return { ...response }
  } else return response
}
