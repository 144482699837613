import { initializeApp } from 'firebase/app'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'

export const test = process.env.PUBLIC_ENV !== 'prod'
export const projectId = test ? 'talent-point-test' : 'talent-point'

export const firebaseConfig = {
  apiKey: process.env.PUBLIC_API_KEY,
  authDomain: process.env.PUBLIC_AUTH_DOMAIN,
  projectId: process.env.PUBLIC_PROJECT_ID,
  storageBucket: process.env.PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.PUBLIC_APP_ID,
  measurementId: process.env.PUBLIC_MEASUREMENT_ID
}
export const app = initializeApp(firebaseConfig)
export const functions = getFunctions(app, 'europe-west1')
if (process.env.NODE_ENV === 'development' && process.env.PUBLIC_USE_EMU !== 'false') {
  connectFunctionsEmulator(functions, 'localhost', 5001)
}
