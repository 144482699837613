import { sortByDateDesc } from './dates'

export const initialYearOfExperience = { name: '', years: null }
export const initialWorkHistory = { start: null, end: null, company: '', title: '' }
export const initialEducation = { start: null, end: null, school: '', degree: '' }
export const initialCertificate = { date: null, name: '', additional_info: '' }
export const talentObject = {
  id: null,
  firstName: null,
  lastName: null,
  linkedInUrl: null,
  phoneNumber: '+358',
  email: null,
  shortDescription: null,
  photo: null,
  tags: [],
  industry: '',
  location: '',
  jobs: [],
  languages: {},
  about: null,
  skills: [{ type: 'paragraph', children: [{ text: '' }] }],
  yearsOfExperiences: [{ ...initialYearOfExperience }],
  workHistories: [{ ...initialWorkHistory }],
  educations: [{ ...initialEducation }],
  certificates: [{ ...initialCertificate }]
}

export const getTalentWithOrderedDates = talent => {
  const talentCopy = { ...talent }
  /* Set collections with dates in order */
  if (talentCopy.workHistories && talentCopy.workHistories.length > 1) {
    talentCopy.workHistories = sortByDateDesc(talentCopy.workHistories, 'start')
  }
  if (talentCopy.workHistories && talentCopy.educations.length > 1) {
    talentCopy.educations = sortByDateDesc(talentCopy.educations, 'start')
  }
  if (talentCopy.workHistories && talentCopy.certificates.length > 1) {
    talentCopy.certificates = sortByDateDesc(talentCopy.certificates, 'date')
  }
  return talentCopy
}
