import { defer, redirect } from 'react-router-dom'
import { pathJobsList } from '../routes'
import talentRep from '../talents/talentRep'
import Job from './Job'
import { fetchConsultants, fetchJobTitlesEn, fetchTalents } from './AssignmentFetch'
import jobRep from './JobsRep'
import { HIRED_STATUSES } from './PresentedTalentsList'

export async function JobAddViewLoader ({ params }) {
  const jobTitlesEn = fetchJobTitlesEn()
  const assignmentData = fetchAssignment(params.assignmentId)

  return defer({ assignmentData, jobTitlesEn })
}

const fetchAssignment = async (id) => {
  let assignment = null
  if (id && id !== 'new') {
    assignment = await jobRep.getJob(id)
    if (!assignment) {
      console.log('Invalid ID')
      return redirect(pathJobsList)
    }
  }
  const [consultants, talents] = await Promise.all([fetchConsultants(), fetchTalents()])
  if (assignment && assignment.presentedTalents) assignment.presentedTalents = await fetchMissingInfo(assignment.id, assignment.presentedTalents, talents)
  if (assignment && consultants && talents) assignment = new Job(assignment, talents, consultants)
  else assignment = new Job()
  return { assignment, consultants, talents }
}

const fetchMissingInfo = async (assignmentId, presentedTalents, talents) => {
  if (!presentedTalents) return null
  return await Promise.all(presentedTalents.map(async talent => {
    const hiredStatus = await talentRep.getTalentStatus(talent, assignmentId)
    if (hiredStatus) return addMissingInfo(talent, hiredStatus, talents)
  }))
}

const addMissingInfo = (talent, hiredStatus, talents) => {
  const hiredStatusObject = hiredStatus?.status?.pop()
  let hiredStatusString = null
  if (hiredStatusObject.hired) hiredStatusString = HIRED_STATUSES.hired
  else if (hiredStatusObject.rejected) hiredStatusString = HIRED_STATUSES.rejected
  return { id: talent, name: addNameToPresentedTalent(talent, talents), hiredStatus: hiredStatusString }
}

const addNameToPresentedTalent = (talentId, talents) => {
  return talents.find(talent => talent.id === talentId).name
}
