import { defer } from 'react-router-dom'
import { getAllCommentsForCards, getJobNames } from './TalentCardFunctions'
import talentRep from './talentRep'

export const TalentsListScreenLoader = async () => {
  const allJobNames = getJobNames()
  const allTalents = talentRep.getTalents()
  const allComments = getAllCommentsForCards()
  return defer({ allTalents, allJobNames, allComments })
}
