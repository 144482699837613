/** Abstract Row class */
export default class Row {
  #data

  /**
    * Create row
    *
    * @param {String} data The CSV like row.
    */
  #id
  constructor (id, data = '') {
    this.#id = id
    this.#data = data
  }

  /**
   * Firestore document ID
   *
   * @returns {String} Document ID
   */
  get id () { return this.#id }

  get type () { return this.constructor.name.replace('Row', '') }

  data (index) {
    if (this.#data.length <= index) {
      throw Error(`${this.type} doesn't have ${index} index!`)
    }
    return this.#data[index]
  }

  toString () { return `${this.type}#${this.id}` }
}
