import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Chip from '@mui/material/Chip'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import { COLORS } from '@saima/common/theme/talentPoint'

import PresentedTalentSelect from './PresentedTalentsSelect'

export const HIRED_STATUSES = {
  hired: 'hired',
  rejected: 'rejected',
  clear: null
}

/**
 * Sorts talents alphabetically in categories: null > hired > rejected
 * @param {[{id: string, name: string, hiredStatus: string}]} value
 * @returns sorted array of objects
 */
export const sortTalents = (value) => {
  return [...value].sort((a, b) => {
    if (a.hiredStatus === null && b.hiredStatus === null) {
      if (a.name < b.name) return -1
      else return 1
    }
    if (a.hiredStatus === null) return -1
    if (b.hiredStatus === null) return 1
    if (a.hiredStatus === b.hiredStatus && a.name < b.name) return -1
    if (a.hiredStatus === b.hiredStatus && a.name > b.name) return 1
    if (a.hiredStatus > b.hiredStatus) return 1
    if (a.hiredStatus < b.hiredStatus) return -1
    return 0
  })
}

export default function PresentedTalentsList ({ value, onChange, label, disabled, talents, onClick }) {
  const changeStatus = (id, status) => {
    const newPresentedTalentsArray = [...value]
    const index = newPresentedTalentsArray.findIndex(talent => talent.id === id)
    newPresentedTalentsArray[index].hiredStatus = status
    return newPresentedTalentsArray
  }

  // create object with keys as hiredStatus and amount
  function hiredStatuses (data) {
    if (!data) {
      return {
        hired: 0,
        rejected: 0
      }
    }
    return data.reduce((acc, item) => {
      acc[item?.hiredStatus] = acc[item?.hiredStatus] ? acc[item?.hiredStatus] + 1 : 1
      return acc
    }, {})
  }
  const onDelete = (talentId) => {
    const newPresentedTalentsArray = [...value].filter(item => item.id !== talentId)
    onChange(newPresentedTalentsArray)
  }

  const onHire = (talentId) => {
    const newPresentedTalentsArray = changeStatus(talentId, HIRED_STATUSES.hired)
    onChange(newPresentedTalentsArray)
  }

  const onReject = (talentId) => {
    const newPresentedTalentsArray = changeStatus(talentId, HIRED_STATUSES.rejected)
    onChange(newPresentedTalentsArray)
  }

  const onClear = (talentId) => {
    const newPresentedTalentsArray = changeStatus(talentId, HIRED_STATUSES.clear)
    onChange(newPresentedTalentsArray)
  }

  const onAddTalent = (newTalent) => {
    if (!newTalent) return
    const newPresentedTalentsArray = value ? [...value] : []
    newPresentedTalentsArray.push(newTalent)
    onChange(newPresentedTalentsArray)
  }

  let totalTalents = value ? value.length : 0

  return (
    <Stack sx={{ marginTop: 0 }}>
      <PresentedTalentSelect
        disabled={disabled}
        addTalent={(val) => onAddTalent(val)}
        label='Add talent'
        talents={talents || []}
      />
      <Typography variant='caption'>
        <b>{label}</b> :{' '}
        {Object.entries(hiredStatuses(value)).map(([key, value]) => {
          if (key === 'null' || key === 'undefined') return null
          if (key === HIRED_STATUSES.hired || key === HIRED_STATUSES.rejected) {
            totalTalents -= value
          }
          return (
            <Chip
              size='small'
              key={key}
              label={`${value} ${key}`}
              sx={{
                backgroundColor: key === HIRED_STATUSES.hired ? COLORS.green : COLORS.red,
                color: '#000',
                marginRight: 1
              }}
            />
          )
        })}
        {totalTalents > 0 && <Chip size='small' sx={{ backgroundColor: COLORS.orange }} label={`${totalTalents} active`} />}
      </Typography>

      <List sx={{ maxHeight: 200, overflow: 'auto' }} dense>
        {value &&
          sortTalents(value).map((talent) => {
            return (
              <CustomListItem
                key={talent?.id}
                talent={talent}
                onHire={onHire}
                onReject={onReject}
                onClick={onClick}
                onClear={onClear}
                onDelete={onDelete}
                hiredBackgroundColor={{
                  backgroundColor:
                    talent?.hiredStatus === HIRED_STATUSES.hired
                      ? COLORS.green
                      : 'default'
                }}
                rejectBackgroundColor={{
                  backgroundColor:
                    talent?.hiredStatus === HIRED_STATUSES.rejected
                      ? COLORS.red
                      : 'default'
                }}
              />
            )
          })}
      </List>
    </Stack>
  )
}

function CustomListItem ({ talent, onHire, onReject, onClick, onClear, onDelete, hiredBackgroundColor, rejectBackgroundColor }) {
  const hire = (id) => {
    if (talent?.hiredStatus === HIRED_STATUSES.hired) onClear(id)
    else onHire(id)
  }

  const reject = (id) => {
    if (talent?.hiredStatus === HIRED_STATUSES.rejected) onClear(id)
    else onReject(id)
  }

  return (
    <ListItem sx={{ margin: 0 }}>
      <ListItemButton onClick={() => onClick(talent?.id)}>
        <ListItemText primary={`${talent?.name || ''}`} />
      </ListItemButton>
      <Stack alignItems='center' direction='row' spacing={1}>
        <Chip sx={{ backgroundColor: hiredBackgroundColor }} onClick={() => hire(talent?.id)} size='small' label='Hire' />
        <Chip sx={{ backgroundColor: rejectBackgroundColor }} onClick={() => reject(talent?.id)} size='small' label='Reject' />
        <IconButton size='small' onClick={() => onDelete(talent?.id)}><DeleteIcon /></IconButton>
      </Stack>
    </ListItem>
  )
}
