/* eslint-disable camelcase */
import { OAuthProvider } from '@firebase/auth'
import LinkedInFunctions from './LinkedInFunctions'
import { buildUrl } from '../utils/urls'

const callbackUrl = '/auth/linkedIn/callback'
const linkedInFunctions = new LinkedInFunctions()
export const linkedInId = 'linkedin.com'
const redirect_uri = window.location.origin + callbackUrl // eslint-disable-line
const response_type = 'code' // eslint-disable-line
/**
 * [Lite Profile](https://docs.microsoft.com/en-us/linkedin/shared/references/v2/profile/lite-profile)
 * [Basic Profile](https://docs.microsoft.com/en-us/linkedin/shared/references/v2/profile/basic-profile)
 * [Full Profile](https://docs.microsoft.com/en-us/linkedin/shared/references/v2/profile/full-profile)
 */
export const scope = Object.freeze({
  basicProfile: 'r_basicprofile',
  emailAddress: 'r_emailaddress',
  fullProfile: 'r_fullprofile',
  liteProfile: 'r_liteprofile',
  primaryContact: 'r_primarycontact',
  memberSocial: 'w_member_social'
})
const stateKey = 'linkedInState'

export function randomState () {
  return Math.random().toString().slice(2)
}

/**
 * LinkedIn OAuth2 authorization client.
 * https://docs.microsoft.com/en-us/linkedin/shared/authentication/authorization-code-flow?context=linkedin%2Fcontext&tabs=HTTPS
 */
export default class LinkedInAuthProvider extends OAuthProvider {
  /**
   * Create LinkedIn OAuth2 authorization client.
   * @param {string} clientId The API Key value generated when you registered your application.
   * @param {string[]} scopes List of member permissions your application is requesting on behalf of the user. These must be explicitly requested.
   * Defaults to `r_liteprofile` and `r_emailaddress`.
   * See [Permissions](https://docs.microsoft.com/en-us/linkedin/shared/authentication/authentication?context=linkedin/context#permission-types) and
   * [Best Practices for Application Development](https://docs.microsoft.com/en-us/linkedin/shared/api-guide/best-practices/application-development?context=linkedin/context)
   * for additional information.
   */
  constructor (clientId, scopes = [scope.emailAddress, scope.liteProfile]) {
    super('linkedin.com')
    window.console.debug('Scopes to request from LinkedIn', scopes)
    scopes.forEach(scope => {
      this.addScope(scope)
    })

    /** @type {string} The API Key value generated when you registered your application. */
    this.clientId = clientId
  }

  buildAuthorizationUrl (state) {
    return buildUrl(`https://www.${this.providerId}/oauth/v2/`, 'authorization', {
      client_id: this.clientId,
      redirect_uri,
      response_type,
      scope: this.getScopes().join(' '),
      state
    })
  }

  getAuthorizationCode (
    url = window.location,
    state = window.localStorage.getItem(stateKey)
  ) {
    if (url.pathname !== callbackUrl) return null
    const params = new URLSearchParams(url.search)

    if (params.get('state') !== state) {
      throw new Error('You are likely the victim of a CSRF attack')
    }

    if (params.has(response_type)) return params.get(response_type)

    const error = params.get('error')
    const message = params.get('error_description')
    switch (error) {
      case 'user_cancelled_authorize':
      case 'user_cancelled_login':
        break
      case 'unauthorized_scope_error':
      default:
        console.error(
          "Sign In with LinkedIn isn't configured properly!",
          error, message
        )
    }
    throw new Error(message)
  }

  async signInWithAuthorizationCode (code) {
    window.console.debug('Sign in with LinkedIn', code)
    const result = await linkedInFunctions.signIn(code, redirect_uri)
    return result.token
  }

  /**
   * https://docs.microsoft.com/en-us/linkedin/shared/authentication/authorization-code-flow?context=linkedin%2Fconsumer%2Fcontext&tabs=HTTPS#step-2-request-an-authorization-code
   */
  signInWithRedirect () {
    const state = randomState()
    window.localStorage.setItem(stateKey, state)
    window.location.assign(this.buildAuthorizationUrl(state))
  }
}
