import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { app } from '../firebaseApp'
import production from '../../utils/production'

export const storage = getStorage(app) // default

if (!production && process.env.PUBLIC_USE_EMU !== 'false') {
  const emulatorLocation = window.location.href.split('/')[2].replace(':3000', '')
  connectStorageEmulator(storage, emulatorLocation, 9199)
}
