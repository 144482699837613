import FirestoreTopCollection from '../google/firestore/FirestoreTopCollection'
import Jobs from '@saima/common/recruiting/Jobs'

class ViewsRepository extends FirestoreTopCollection {
  #jobs = null
  constructor () {
    super('views')
  }

  /**
   * Returns jobTitle_en object containing translated job titles
   * @param {string} lang language of translations (only english atm)
   * @returns {object} object with all job titles
   */
  async getJobTitlesTranslations (lang = 'en') {
    let jobTitlesTranslations = {}
    if (lang === 'en') {
      jobTitlesTranslations = await this.getById('jobTitles_en')
    }
    return jobTitlesTranslations
  }

  /**
   * Gets consultants object from views database
   * @returns {Object} Consultants objects
   */
  async getAllConsultants () {
    return await this.getById('consultants')
  }

  /**
   * Gets talents object from views database
   * @returns {Object} Talents objects
   */
  async getAllViewsTalents () {
    return await this.getById('talents')
  }

  async getJobs () {
    if (!this.#jobs) {
      this.#jobs = new Jobs(await this.getById('jobs'))
    }
    return this.#jobs
  }
}

const viewsRep = new ViewsRepository()
export default viewsRep
