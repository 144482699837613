import commentsRep from './CommentRep'
import occupationRep from './occupationRep'

/**
 * Returns all comments
 * @returns {[Object]}
 */
export const getAllCommentsForCards = async () => {
  let comments = null
  comments = await commentsRep.getAll().catch(error => console.debug(error))
  return comments
}

/**
 * Finds comment to talent using with id
 * @param {[object]} comments All comments loaded previously
 * @param {string} id The id of talent
 * @returns {[string, string]}
 */
export const getACommentForCard = (comments, id) => {
  const selectedComment = comments.find(element => element.id === id)
  if (selectedComment && selectedComment.comment) {
    return [selectedComment.comment, selectedComment.status]
  }
  return null
}

export const getJobNames = async (lang = 'en') => {
  return await occupationRep.getAllInSingleLang(lang)
}

export const parseLanguages = (languages) => {
  if (languages) {
    const languageArray = []
    Object.entries(languages).forEach(value => {
      if (value[1]) {
        languageArray.push(value[0])
      }
    })
    return languageArray
  }
}

export const parseLanguagesForFiltering = (languages) => {
  if (languages) {
    let languageString = ''
    Object.entries(languages).forEach(value => {
      if (value[1]) {
        languageString = languageString + ' ' + value[0]
      }
    })
    return languageString
  }
}

export const getStatusForCard = (comment) => {
  if (comment && comment.status) {
    return comment.status
  }
  return null
}
