import FirestoreTopCollection from '../google/firestore/FirestoreTopCollection'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../google/firestore/firebaseFirestore'
import AdoreJob from '@saima/common/Job/AdoreJob'
import AdoreJobInterest from '@saima/common/recruiting/AdoreJobInterest'

class AdoreJobRepository extends FirestoreTopCollection {
  constructor () {
    super('a')
  }

  async getJobDocument (id) {
    const res = await this.getById(id)
    return new AdoreJob(res)
  }

  async getJobInterests (id) {
    if (!id) return []
    const ref = await getDocs(collection(db, `a/${id}/i`))
    const docArray = []
    if (ref.empty) return docArray
    ref.forEach((item) => docArray.push({ ...item.data(), id: item.id }))
    return docArray.map((item) => new AdoreJobInterest(item))
  }
}

const adoreJobRep = new AdoreJobRepository()
export default adoreJobRep
