import { sites } from '../utils/sites'
import { useMemo, useState } from 'react'
import Locale, { localeContext } from '@saima/common/i18n'

/** Supported ISO 639-1 language codes */
const talentPointLanguages = ['en', 'uk', 'ru']
const staffPointLanguages = ['en', 'fi']
const languages = getSiteLanguages()
function parseLanguage (lang) {
  return lang ? lang.split('-')[0] : null
}

function getSiteLanguages () {
  if (window.location.href.startsWith(sites.staffPoint)) return staffPointLanguages
  else if (window.location.href.startsWith(sites.talentpoint)) return talentPointLanguages
  else return talentPointLanguages.concat(staffPointLanguages)
}

export function detectLanguage () {
  // Always prefer localStorage first
  let lang = window.localStorage.getItem('lang')
  if (languages.includes(lang)) return lang

  // Next check URL
  if (window.location.href.endsWith('ukraine')) return 'uk'

  // Then check navigator language
  lang = parseLanguage(navigator.language)
  if (languages.includes(lang)) return lang

  // Internet explorer includes lang code in different place
  lang = parseLanguage(navigator.userLanguage)
  if (languages.includes(lang)) return lang

  return 'en'
}

const i18nSettings = {
  fallbackLng: languages[0],
  nsSeparator: false,
  react: {
    useSuspense: false
  }
}
export const LocaleProvider = ({ children }) => {
  const [lang, setLang] = useState(detectLanguage())
  const localeObject = new Locale(lang, setLang, { talentPoint: true }, i18nSettings)
  const localeMemo = useMemo(() => localeObject, [localeObject])
  return (
    <localeContext.Provider value={localeMemo}>{children}</localeContext.Provider>
  )
}
