import dayjs from 'dayjs'

class AdoreJobInterest {
  /**
   * @param data
   * @param {string} data.a serverTimestamp
   * @param {string} data.n name
   * @param {string} data.c employer
   * @param {number} data.t contract
   * @param {string} data.w workTime
   * @param {string} data.h weeklyHours
   * @param {string} data.p phone
   * @param {string} data.e email
   * @param {string} data.i interested
   * @param {string} data.o collectiveAgreement
   */

  #contract
  #serverTimestamp
  #interested
  #workTime

  constructor (data) {
    this.#serverTimestamp = data?.a
    this.id = data?.id
    this.name = data?.n
    this.employer = data?.c
    this.#contract = data?.t
    this.#workTime = data?.w
    this.weeklyHours = data?.h
    this.phone = data?.p
    this.email = data?.e
    this.#interested = data?.i
    this.collectiveAgreement = data?.o
  }

  get contract () {
    const typeOfContract = [
      'No contract',
      'Fixed term',
      'Permanent',
      'On call work'
    ]
    return typeOfContract[this.#contract]
  }

  get serverTimestamp () {
    return dayjs(this.#serverTimestamp.toDate()).format('YYYY-MM-DD')
  }

  get workTime () {
    const typeOfWorkTime = [
      '',
      'Regular working hours',
      'Period work time',
      'Piecework',
      'Varying work time'
    ]
    if (this.#workTime === null) return ''
    return typeOfWorkTime[this.#workTime]
  }

  get interested () {
    if (typeof this.#interested === 'string') {
      return this.#interested === 'True'
    } else {
      return this.#interested
    }
  }
}

export default AdoreJobInterest
