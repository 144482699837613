import axios from 'axios'
import { projectId } from '../firebaseApp'
import production from '../../utils/production'

const defaultLocation = 'us-central1'
const location = 'europe-west1'
const rewriteUlrs = location === defaultLocation

export default class FirebaseFunctionGroup {
  constructor (group, prefix) {
    const config = {}
    if (production) {
      if (!rewriteUlrs) {
        config.baseURL = `https://${location}-${projectId}.cloudfunctions.net`
      }
    } else {
      const emulatorLocation = window.location.href.split('/')[2].replace(':3000', ':5001')
      config.baseURL = `http://${emulatorLocation}/${projectId}/${location}`
    }
    this._client = axios.create(config)
    this._group = group
    this._prefix = prefix
  }

  _path (path) {
    return production && rewriteUlrs
      ? `/${this._prefix}/${this._group}/${path}`
      : `${this._group}-${path}`
  }

  async _put (path, data) {
    return await this._client.put(this._path(path), data)
  }

  /**
   * HTTP PUT request where response data is JSON.
   * @param {string} path URL Path
   * @param {object} data HTTP body data object
   * @return {Promise<object>} Promise of data object
   */
  async _putToData (path, data) {
    const response = await this._put(path, data)
    return response.data
  }
}
